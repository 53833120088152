import React from 'react'
import SubPageHeader from '../components/subPageHeader'
import Footer from '../components/footer'
import { useGetToken } from '../hooks/use-get-token'
import SEO from '../components/seo'

import { useGetFavoriteWorkshop } from '../hooks/use-favorite-workshop'

const MyPageLayout = ({ title, children }) => {
  const workshop = useGetFavoriteWorkshop()
  const token = useGetToken()

  return (
    <React.Fragment>
      <SEO title={title} />
      <SubPageHeader workshop={workshop} token={token} />
      <main style={{ marginTop: '9rem' }}>{children}</main>
      <Footer />
    </React.Fragment>
  )
}

export default MyPageLayout
