import { ErrorMessage } from '../styled/errorMessage'
import { Label } from '../styled/forms'
import TextField from '../web-order-v3/Field/textfield'
import { PrimaryButton } from '../styled/buttons'
import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useLogin } from '../../hooks/use-login'
import styled from 'styled-components'
const LoginFormStyled = styled.form`
  width: 100%;

  margin-right: 1rem;
  padding: 2rem;
  background: #222222;

  h1 {
    font-weight: 700;
  }

  h1,
  ${Label} {
    color: white;
  }
`
const FormGrid = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  margin: 15px auto;
`

const LoginForm = () => {
  const { loading, login, error } = useLogin()
  const [phone, setPhone] = useState('')
  const [regno, setRegno] = useState('')

  const doLogin = async (e) => {
    e.preventDefault()
    const { token } = await login(phone, regno)
    localStorage.setItem('bilxtra-web-auth', token)
    navigate(`/min-side?regno=${regno}`)
  }

  return (
    <LoginFormStyled onSubmit={doLogin}>
      <h1>Bileiers side</h1>
      {error && <ErrorMessage>Det oppstod en feil.</ErrorMessage>}

      <FormGrid>
        <TextField
          type="phone"
          name="phone"
          value={phone}
          placeholder="Telefonnummer"
          onChange={(value) => setPhone(value)}
        ></TextField>

        <TextField
          name="regno"
          value={regno}
          placeholder="Reg. nr"
          onChange={(value) => setRegno(value.toUpperCase())}
        ></TextField>
      </FormGrid>
      <PrimaryButton type="submit" disabled={(!phone && !regno) || loading ? 'disabled' : ''}>
        Logg inn
      </PrimaryButton>
    </LoginFormStyled>
  )
}

export default LoginForm
