import styled from 'styled-components'

export const SecondaryButton = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem;
  font-size: inherit;
  border: 1px solid #333333;
  background: white;
`

export const SecondaryButtonSmall = styled.button`
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  border: 1px solid #333333;
  background: white;
`

export const PrimaryButton = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem;
  color: white;
  font-size: inherit;
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
  transition: background 0.25s ease-out;

  &:disabled {
    border: 1px solid rgba(26, 81, 160, 0.4);
    background: rgba(26, 81, 160, 0.4);
  }
`

export const DangerButtonSmall = styled.button`
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  border: 1px solid var(--error-color);
  color: white;
  background: var(--error-color);
`

export const PrimaryButtonSmall = styled.button`
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  border: 1px solid var(--primary-color);
  color: white;
  background: var(--primary-color);

  &:disabled {
    border: 1px solid rgba(26, 81, 160, 0.4);
    background: rgba(26, 81, 160, 0.4);
  }
`
