import { useState } from 'react'

export const useLogin = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)

  const login = (phone, regno) => {
    setLoading(true)

    return fetch(`${process.env.API_URL}auth/bilxtra-web`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone, regno }),
    }).then(async (response) => {
      setLoading(false)

      if (!response.ok) {
        setError('An error occurred')
      }

      return response.json()
    })
  }

  return {
    loading,
    login,
    error,
  }
}
