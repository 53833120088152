import React from 'react'
import styled from 'styled-components'
import MyPageLayout from '../layouts/my-page-layout'
import LoginForm from '../components/my-page/login-form'
import { device } from '../components/device'

const breakpoint = device.tabletMaxL
const LoginDescription = styled.div`
  padding: 2rem;
  color: white;
  box-shadow: 1px 1px 10px #333333;
  background: var(--primary-color);

  ul {
    margin: 0;
    padding-left: 1rem;
  }
`

const LoginDescriptionTitle = styled.h2`
  margin-top: 0;
  font-size: 1.5rem;
`
const HolderBoxses = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  margin: 5px auto;
  @media ${breakpoint} {
    grid-template-columns: 1fr;
  }
`
export const ContainerBoxses = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1348px;
  display: flex;
  align-items: center;
  height: 70%;

  @media ${device.laptopL} {
    padding-left: 0;
    padding-right: 0;
  }
`

const LoginPage = () => {
  return (
    <MyPageLayout title="Bileiers side | Logg inn">
      <ContainerBoxses>
        <HolderBoxses>
          <LoginForm />
          <LoginDescription>
            <LoginDescriptionTitle>
              På bileiers side kan du sjekke blant annet:
            </LoginDescriptionTitle>
            <ul>
              <li>Historikken på en eller flere av bilene dine</li>
              <li>Neste service</li>
              <li>Neste EU-kontroll</li>
              <li>Xtraskjekk skjema</li>
              <li>Mobilitetsgaranti</li>
            </ul>
          </LoginDescription>
        </HolderBoxses>
      </ContainerBoxses>
    </MyPageLayout>
  )
}

export default LoginPage
